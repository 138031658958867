<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer top="3vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="商圈名称" prop="name" style="width: 100%;">
                    <el-input v-model="formPublish.name"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="商铺类型" prop="shopType">
                    <el-select v-model="formPublish.shopType" @change="shopsTypeChange" placeholder="请选择商铺类型">
                        <el-option v-for="(item,index) in shopsTypeList" :key="index" :label="item.name" :value="item.type"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="商铺最高楼层" prop="shopMaxFloorNumber">
                    <el-input-number v-model="formPublish.shopMaxFloorNumber" :min="1" :max="34" style="width: 215px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="区域" prop="province">
                    <el-select v-model="formPublish.province" placeholder="请选择省" @change="provinceChange">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="city">
                    <el-select v-model="formPublish.city" placeholder="请选择市" @change="cityChange">
                        <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="district">
                    <el-select v-model="formPublish.district" placeholder="请选择区">
                        <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
            </div>
            <!--高德地图-->
            <GaoDeMap class="gao-de-map" ref="gaoDeMapRef" show-autocomplete :inputChange.sync="formPublish.gaoDeMapName"
                      @on-autocomplete="handleAutocomplete"/>
            <div class="flex" style="margin-top: 20px">
                <el-form-item label="定位地址" prop="address" style="width: 100%;">
                    <el-input v-model="formPublish.address"/>
                </el-form-item>
            </div>
            <div class="house-manage flex justify-between">
                <el-form-item label="管家姓名" prop="stewardName">
                    <el-select v-model="formPublish.stewardName" placeholder="请选择管家姓名"
                               clearable filterable multiple @change="handleChange">
                        <el-option v-for="item in users" :key="item.username" :label="item.fullname" :value="item.username"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="管家手机号" prop="stewardIphone">
                    <el-input v-model="formPublish.stewardIphone" placeholder="请输入管家手机号码"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import GaoDeMap from "@/components/GaoDeMap";
    import { MessageSuccess, MessageWarning, MessageError } from "@custom/message";
    import { getUserListApi } from "@/api/publish";
    import { communityAdd, communityUpdate } from '@/api/shops-list';

    import cityCode from "@/assets/json/city_code.json";

    export default {
        name: "layer-add-business-district",
        data() {
            return {
                dialogTitle: "新增商铺",
                dialogVisible: false,
                formPublish: {
                    uuid: null,
                    name: null,
                    province: null,
                    city: null,
                    district: null,
                    street: null,
                    address: null,
                    longtitude: null,
                    latitude: null,
                    ichnography: null,
                    shopType: null,
                    shopTypeName: null,
                    shopMaxFloorNumber: 1,
                    stewardName: [],
                    stewardIphone: null,
                    // name	是	string	商圈名称
                    // province	是	string	省
                    // city	是	string	市
                    // district	是	string	区
                    // street	否	string	路
                    // address	是	string	定位详细地址
                    // longtitude	是	double	经度
                    // latitude	是	double	维度
                    // ichnography	否	string	二维图地址
                    // shopType	是	int	商圈类型
                    // shopTypeName	是	string	商铺类型名称
                    // shopMaxFloorNumber	是	int	最高楼层
                    // stewardName	是	string	管家名称
                    // stewardIphone	是	string	管家手机号
                    gaoDeMapName:null,
                },
                users: [],
                rules: {
                    name: [{ required: true, message: '请输入商圈名称', trigger: 'blur' }],
                    shopType: [{ required: true, message: '请选择商铺类型', trigger: 'change' }],
                    shopMaxFloorNumber: [{ required: true, message: '请输入商铺最高楼层', trigger: 'blur' }],
                    province: [{ required: true, message: '请选择省', trigger: 'change' }],
                    city: [{ required: true, message: '请选择市', trigger: 'change' }],
                    district: [{ required: true, message: '请选择区', trigger: 'change' }],
                    address: [{ required: true, message: '请输入定位地址', trigger: 'blur' }],
                    stewardName: [{ required: true, message: '请选择管家姓名', trigger: 'change' }],
                    stewardIphone: [{ required: true, message: '请输入管家手机号码', trigger: 'blur' }],
                },
                shopsTypeList: [
                    {
                        type: 1,
                        name: "住宅底商",
                    },
                    {
                        type: 2,
                        name: "商业街商铺",
                    },
                    {
                        type: 3,
                        name: "临街门面",
                    },
                    {
                        type: 4,
                        name: "写字楼配套底商",
                    },
                    {
                        type: 5,
                        name: "购物中心/百货",
                    },
                    {
                        type: 6,
                        name: "其他",
                    }
                ],
                provinceList: cityCode,
                cityList: [],
                areaList: [],
                type: null,
            };
        },
        components: { GaoDeMap },
        async created() {
            // 获取管理员
            let users = await getUserListApi();
            this.users = users.list;
        },
        methods: {
            openDialog(type,data) {
                // 页面初始化，父页面传递数据
                this.type = type;
                this.dialogVisible = true;
                this.dialogTitle = type === "add" ? "新增商圈" : "编辑商圈";
                if (type === "edit") this.dataInit(data);
            },
            dataInit(row) {
                let oldFormPublish = {...this.formPublish};
                if(row.stewardName) row.stewardName = row.stewardName.split(";");
                // 编辑页面回显数据
                this.formPublish = {...oldFormPublish,...row};
            },
            handleChange(e) {
                // 获取管家手机号
                let mobiles=[];
                for(let item of e){
                    const { mobile } = this.users.find(v => v.username === item);
                    mobiles.push(mobile);
                }
                this.formPublish.stewardIphone = mobiles.join(";");
            },
            handleAutocomplete(data) {
                // 通过地图，选择小区，获取数据
                const {name, district, location, address, adcode} = data;
                const {lng: longtitude, lat: latitude} = location;
                console.log(data);
                let sh = this.provinceList.find(i => i.code.startsWith(adcode.substr(0, 2)));
                this.formPublish.province = sh.name;
                this.provinceChange(sh.name);
                let shi = sh.city.find(i => i.code.startsWith(adcode.substr(0, 4)));
                this.formPublish.city = shi.name;
                this.cityChange(shi.name);
                let qu = shi.area.find(i => i.code === adcode);
                this.formPublish.district = qu.name;
                //this.formPublish = { name, address, district, longtitude, latitude };
                this.formPublish.address = district + name + address;
                this.formPublish.longtitude = longtitude;
                this.formPublish.latitude = latitude;
            },
            shopsTypeChange(value){
                let type = this.shopsTypeList.find(item => item.type === value);
                if (type) this.formPublish.shopTypeName = type.name;
            },
            clickSubmit() {
                // 表单提交
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let data = this.formPublish;
                        data.stewardName = data.stewardName.join(";");
                        if (data.longtitude === null || data.latitude === null) {
                            MessageWarning("经纬度为空，请在地图中选择地址", 1000);
                            return;
                        }
                        if (this.type === "add") this.communityAdd({...data});
                        else this.communityUpdate({...data});
                    } else {
                        MessageWarning("请填写必填值", 1000);
                        return false;
                    }
                });
            },
            communityAdd(data){
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                communityAdd(data).then(res => {
                    MessageSuccess('添加成功');
                    that.clickCancel();
                    loading.close();
                }).catch(err => {
                    MessageError('添加失败');
                    loading.close();
                });
            },
            communityUpdate(data){
                let that = this;
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                communityUpdate(data).then(res => {
                    MessageSuccess('修改成功');
                    that.clickCancel();
                    loading.close();
                }).catch(err => {
                    MessageError('修改失败');
                    loading.close();
                });
            },
            clickCancel() {
                // 取消、关闭弹窗
                this.dialogVisible = false;
                this.formPublish = {
                    uuid: null,
                    name: null,
                    province: null,
                    city: null,
                    district: null,
                    street: null,
                    address: null,
                    longtitude: null,
                    latitude: null,
                    ichnography: null,
                    shopType: null,
                    shopTypeName: null,
                    shopMaxFloorNumber: 1,
                    stewardName: [],
                    stewardIphone: null,
                    gaoDeMapName: null
                };
                this.$refs["formPublish"].resetFields();
                this.$parent.handleSearch();
                this.cityList = [];
                this.areaList = [];
            },

            provinceChange(value) {
                const {city, code, name} = this.provinceList.find(i => i.name === value);
                this.formPublish.city = null;
                this.formPublish.district = null;
                this.cityList = city;
                this.areaList = [];
            },
            cityChange(value) {
                const {area, code, name} = this.cityList.find(i => i.name === value);
                this.formPublish.district = null;
                this.areaList = area;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .layer-add-business-district {
        .gao-de-map {
            width: 100%;
            height: VH(400px);
        }

        .house-manage .el-form-item {
            width: 50%;

            .el-select {
                width: 100%;
            }
        }


    }
</style>
